@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.product-hero-container {
  padding: 0;
}

@media screen and (min-width: var.$breakpoint-lg) {
  .product-hero-container {
    padding: fn.spacing(4) var.$medium-and-up-gutter-width fn.spacing(8);
  }
}
