@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.button {
  width: auto;
  position: relative;
}

.a {
  text-decoration: none;
  color: inherit;

  ::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}

.second-button-container {
  margin: fn.spacing(2) 0 fn.spacing(4);
}

.icon-container {
  padding: 0 fn.spacing(2);
  margin: fn.spacing(4) 0 fn.spacing(2);
}

// be more specific here to take precedence over the default className
svg.icon {
  transform: translateY(5px);
  margin-right: fn.spacing(1);
}

.card {
  background: var.$color-neutral-background-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: fn.spacing(5) fn.spacing(3);
  height: 100%;
  border: 1px solid var.$color-error-text-disabled;
  border-radius: var.$achieve-border-radius-2;
  text-align: center;
  position: relative;

  &[data-with-badge] {
    padding: fn.spacing(5) fn.spacing(3) fn.spacing(3);
  }

  // Intentionally make this className more specific than component `.eyebrow` className
  .card-eyebrow {
    padding-bottom: fn.spacing(3);
  }
}

.card-image {
  margin-bottom: fn.spacing(3);
}

.card-title {
  margin-bottom: fn.spacing(1);
}

.card-subtitle {
  width: 100%;
  padding-bottom: fn.spacing(1);
  margin-bottom: fn.spacing(2.5);
  border-bottom: 1px solid var.$color-neutral-grey-5;
}

.card-copy-content {
  margin-bottom: fn.spacing(3);
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .card {
    padding: fn.spacing(6) fn.spacing(4);

    &[data-with-badge] {
      padding: fn.spacing(6) fn.spacing(4) fn.spacing(4);
    }
  }

  .card-copy-content {
    flex-grow: 2;
  }
}
