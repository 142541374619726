@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.call-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: fn.spacing(1);
  border-radius: 48px;
  cursor: pointer;
  min-height: 48px;
  margin-left: 24px;
}

.button-container {
  width: 100%;

  > span > a {
    text-wrap: nowrap;
  }
}

.title-link {
  margin: 0 16px;
}

.call-cta-link {
  width: 24px;
  height: 24px;
}

.call-cta-container {
  width: 100%;
  max-width: 100%;
}

[data-open-button-call='false'].call-cta-container {
  display: flex;
  justify-content: center;
}

[data-open-button-call='true'].call-cta {
  overflow: hidden;
  transition: all 0.2s;
  min-width: 100%;
  margin: 0;
  border-radius: fn.spacing(3);
  padding: fn.spacing(1) fn.spacing(2);
}

[data-open-button-call='false'].call-cta {
  justify-content: center;
  min-width: 48px;
  transition: all 0.2s ease-out;
}

[data-color-variant='white'].call-cta {
  background: var.$color-neutral-white;
}

[data-color-variant='blue'].call-cta {
  background: var.$color-primary-achieve-blue;
}

[data-color-variant='blue'].text {
  color: var.$color-neutral-white;
}

[data-color-variant='white'].text {
  color: var.$color-primary-achieve-blue;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .call-cta-container {
    margin: 0;
  }

  .button-container {
    width: 80%;
  }
}
