@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.eyebrow {
  color: var.$color-primary-achieve-blue;
  text-align: center;
  padding-bottom: fn.spacing(2);
  letter-spacing: 0.25em;
  text-transform: uppercase;
}

.eyebrow[data-variant='black'] {
  color: var.$color-neutral-black;
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .eyebrow {
    padding-bottom: fn.spacing(4);
  }
}
