@use 'styles/variables' as var;
@use 'styles/functions' as fn;

//Mobile styles
.subnav-section {
  background: var.$color-primary-highlight-blue2;
  min-height: 50px;
  box-shadow: inset 0 10px 10px -15px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: top 0.05s linear 0.05s;
}

.subnav-section[data-fixed='true'] {
  justify-content: center;
  position: sticky;
  z-index: 9;
  transform: translateY(-1px);
  width: 100%;
}
