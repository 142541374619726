@use 'styles/functions' as fn;

.app-store-buttons {
  display: flex;
}

.button-apple {
  height: 38px;
  width: 118px;
  margin-right: fn.spacing(2);
}

.button-google {
  width: 134px;
  height: 38px;
}

.outline-link {
  margin-top: fn.spacing(4);
}
