@use 'styles/variables' as var;
@use 'styles/functions' as fn;

// Mobile styles, smaller than large breakpoint
.hero-section {
  margin: 0;
  padding: fn.spacing(4) fn.spacing(0);
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-lg) {
  .hero-section {
    margin: 0;
    padding: fn.spacing(8) 0;
  }
}
