@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.card {
  display: flex;
  flex-direction: column;
  margin-bottom: fn.spacing(3);

  .card-info {
    flex: auto;
    display: flex;
    flex-direction: column;
    text-align: center;

    .card-text-subtitle {
      margin-top: fn.spacing(1);
    }
  }
}

.card-content {
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  padding: fn.spacing(2);
  text-align: center;

  &:last-child {
    padding-bottom: fn.spacing(2);
  }
}

.title-typography {
  margin-bottom: fn.spacing(2);
  text-align: center;
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .card {
    flex-direction: column;
    margin-bottom: 0;

    .card-info {
      text-align: center;
      margin-top: fn.spacing(3);
    }

    &.breakline {
      .card-info {
        .card-text-title {
          margin-top: 0;
        }
      }
    }
  }
}
