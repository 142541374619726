.home-hero-container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  position: relative;

  &[data-narrow] {
    max-width: 100%; // Ensure the home hero container is not affected by narrow content container css
  }
}

.home-hero-section {
  overflow: hidden;
}
