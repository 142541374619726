@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.card {
  align-items: center;
  background: var.$color-neutral-background-default;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: fn.spacing(6) fn.spacing(3);
  text-align: center;
  position: relative;
  box-shadow: none;

  &.card-recommended {
    padding: 0 fn.spacing(3) fn.spacing(6);
  }
}

.card-image {
  margin-bottom: fn.spacing(3);
}

.card-title {
  margin-bottom: fn.spacing(3);
}

.card-subtitle {
  // margin-bottom: fn.spacing(3);
  margin-bottom: fn.spacing(5);

  .card-recommended & {
    margin-bottom: fn.spacing(3);
  }
}

.card-copy-container {
  display: flex;
  flex-direction: column-reverse;
}

.card-recommended-items {
  border-radius: 8px;
  border: 1px solid var.$color-neutral-grey-5;
  display: flex;
  flex-direction: column;
  // margin: 0 0 fn.spacing(3);
  margin: 0 0 fn.spacing(5);
  padding: fn.spacing(3);
  text-align: left;
  width: 100%;
}

.card-recommended-item {
  align-items: center;
  display: flex;

  & + .card-recommended-item {
    margin-top: fn.spacing(2);
  }
}

.recommended-item-icon {
  color: var.$color-primary-achieve-blue;
  margin-right: fn.spacing(2);
  height: 20px;
  width: auto;
}

.card-top-choice {
  background: var.$color-success-background-weak;
  color: var.$color-chip-green-dark;
  margin-bottom: fn.spacing(3);
  padding: fn.spacing(0.25) fn.spacing(1);
  border-radius: fn.spacing(2);
}

@media only screen and (min-width: var.$breakpoint-lg) {
  .card {
    background: var.$color-neutral-background-default;
    border-radius: var.$card-border-radius;
    min-height: 350px;
    border: 1px solid var.$color-primary-border-disabled;
    padding: fn.spacing(6) fn.spacing(4);
    max-width: 384px;

    &.card-recommended {
      padding: fn.spacing(6) fn.spacing(4);
    }
  }

  .card-subtitle {
    margin-bottom: fn.spacing(3);
  }

  .card-recommended-items {
    margin: 0 0 fn.spacing(3);
  }

  .card-top-choice {
    position: absolute;
    right: fn.spacing(3);
    top: fn.spacing(3);
  }

  .card-copy-container {
    display: flex;
    flex-direction: column;
  }
}
