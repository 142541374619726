@use 'styles/variables' as var;

.hero-media-content-image,
.hero-media-content-video {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.image {
  opacity: 1;
}

.video {
  width: auto;
  min-width: 100%;
  min-height: 100vh;
  position: absolute;
  right: 0;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

[data-on-landscape='true'] {
  .video {
    min-width: 100vw;
    height: auto;
    min-height: 100%;
  }
}

.hero-media-content-image {
  box-shadow: inset 0 -85vh 64px 0 rgb(0 0 0 / 55%);
}

// Style changes for desktop styles, larger than large breakpoint
@media only screen and (min-width: var.$breakpoint-lg) {
  .hero-media-content-image {
    box-shadow: inset -60em 0 9em -10em rgb(0 0 0 / 56%);
    padding-left: 40vw;
  }
}
